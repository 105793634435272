import { createRouter, createWebHistory } from 'vue-router'

const routes = [

    {
        path: "/",
        name: 'BueatyMango.vue',
        component: () => import('@/view/BueatyMango.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
